.our-ships {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;

  .our-ships-title {
    font-size: 32px;
    font-weight: 900;
  }
  .our-ships-description {
    font-size: 13px;
    font-weight: 400;
  }
  .our-ships-cards-container {
    .our-ships-card {
      height: 450px;
      width: 100%;
      overflow: hidden;
      border-radius: 30px;
      overflow: hidden !important;
      display: flex;
      align-items: end;
      background-size: 125%;
      background-size: cover;
      background-position: center center;
      margin-bottom: 2rem;
      cursor: pointer;

      // &:hover {
      //   .our-ships-data {
      //     opacity: 100%;
      //     transition: opacity 0.3s ease-in-out;
      //   }
      //   .our-ships-name {
      //     transform: translateY(0px);
      //     transition: transform 0.3s ease-in-out;
      //   }
      // }

      .our-ships-info {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 20px;
        overflow: hidden !important;
      }
      .our-ships-data {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        opacity: 0%;
        transition: opacity 0.3s ease-in-out;
      }
      .our-ships-name {
        color: white;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 7px;
        transform: translateY(60px);
        transition: transform 0.3s ease-in-out;
      }
      .our-ships-description {
        color: white;
        font-size: 14px;
        max-width: 100%;
        min-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      .our-ships-button {
        margin: 10px 0;
        transition: all 0.3s ease-out;
      }
      .our-ships-button:hover {
        padding: 12px 0;
      }

      .our-ships-image-container {
        background: orange;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: -100 !important;
      }
    }
  }
}
