.admin-setting-table {
  header {
    display: none;
  }

  .custom-pagination-component {
    margin-top: -20px !important;
    display: none;
  }

  .label {
    font-size: 14px;
    color: slategray;
    font-weight: bold;
    letter-spacing: -0.5px;
  }

  .setting-value-text {
    font-size: 13px;
    color: gray;
  }

  @media (max-width: 768px) {
    margin-top: -30px;
  }
}

.setting-update-form {
  .form-input {
    width: 100%;
  }
  textarea {
    width: 100% !important;
    height: 30px !important;
  }
  .setting-update-form-label {
    width: 100%;
    text-align: center;
  }
  .setting-update-form-textarea {
    min-height: 200px;
    width: 100%;
  }
}
