.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0px 20px 20px 20px;
  z-index: 1018;
  background-color: $black-custom !important;
  @include desktop {
    z-index: 1030;
  }
  .mb-3 {
    margin-bottom: 0.3rem !important;
  }

  .signature {
    a {
      margin-right: 0px !important;
      margin-bottom: 0px !important;
      display: flex;
      justify-content: center;
      font-size: 10px;
    }
  }

  .footer-width {
    margin-left: -5px;
    width: 127px !important;
  }

  a {
    color: $gray-medium-custom;
    text-decoration: none;
    padding: 7px 12px;
    border-radius: 30px;
    margin-right: 10px;
    font-family: var(--project-font-family);
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    transition: all 0.3s;

    &:focus {
      color: white;
      background: rgba(255, 255, 255, 0.2);
    }

    &:hover {
      color: white;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  p {
    margin: 0 !important;
    font-style: normal;
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .pt-20px {
    padding-top: 20px;
  }

  .subtitle-footer {
    margin-left: -4px !important;
    padding-bottom: 5px !important;
    color: $white;
    font-family: var(--project-font-family);
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 135% !important;
  }

  .number-gray {
    font-weight: 600 !important;
  }
}
