.route-day-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  margin-left: 10px;
}

.route-day-card {
  color: $black-custom;
  border-radius: 20px;
  max-width: 48%;

  @media (max-width: 768px) {
    max-width: 90%;
  }

  .route-day-title {
    margin: 30px;
    font-family: Arial Black;
    font-weight: 900;
    font-size: 18px;
    color: $primary-red;
  }
  .route-day-description {
    font-family: Arial;
    font-weight: 400;
    font-size: 13px;
    color: $black-custom;
    overflow: scroll;
  }
}
