// Imports
@import './settings/index';
@import './components/index';
@import './screens/index';

html {
  position: relative;
  min-height: 100%;

  .navbar-collapse.collapse {
    @media (min-width: 991px) {
      display: flex !important;
    }
  }
}

body {
  font-size: var(--project-font-size) !important;
  font-family: var(--project-font-family) !important;
  padding-top: 0 !important;
  -webkit-font-smoothing: auto !important;
}

.container-layout {
  padding-top: 80px;
  overflow-x: hidden;

  &.import-page-layout {
    padding: 0 !important;
  }
}

.content-body {
  @include desktop {
    margin-left: 250px;
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    max-width: 100vw !important;
    overflow-x: hidden !important;
  }
}

.full-height {
  height: 100vh;
}

.custom-data-table {
  min-height: 350px;
}

header {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.form-control {
  background: $white !important;
  border: 1px solid $gray-light-custom;
  border-radius: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
  height: 36px !important;
  margin-top: 0 !important;
}

.form-group {
  margin-bottom: 10px !important;
}

label {
  font-weight: 700 !important;
  font-size: 13px !important;
  font-family: Arial !important;
  line-height: 130% !important;
  letter-spacing: 0.01em !important;
  margin-bottom: 2px !important;
  margin-top: 5px !important;
}

.form-label {
  margin-bottom: 2px !important;
}

.error-page {
  background: linear-gradient(to bottom, #00adee 0%, #538dff 100%);
  background-size: initial;
  background-repeat: repeat;
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: 'Arial', sans-serif;
    color: white;
    font-size: 170px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 700;
  }
  h2,
  p {
    font-family: 'Arial', sans-serif;
    color: white;
    text-align: center;
    font-weight: 300;
  }
}

.container-layout {
  padding-top: 0px;
}

.hero {
  height: 100vh;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 15vh 0 0 0;
  }

  h1 {
    align-self: center;
    text-align: center;
    color: $white;
  }

  #reserve {
    background-color: $white;
    border-radius: 30px;
    @media (max-width: 768px) {
      min-height: 50vh;
    }
  }
}

.Container-login__image {
  height: 100vh;
  background-image: url(../../../../public/ferry-login-banner.webp);
  background-size: cover;
}

.Container-login__form {
  padding: 0px 10%;
  @media (max-width: 769px) {
    padding: 0px 5%;
  }
}

#login {
  background-color: $primary-red;
}

.card-login {
  padding: 8%;
  background: $white;
  border: 0px;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.card {
  background: $white !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px;
  border: 0px;
}

.card-body {
  min-height: 260px;
  max-height: 260px;
}

.carousel-review {
  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
  img {
    object-fit: cover;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.18));

    @media (min-width: 768px) {
      position: absolute;
    }
  }
}

.title_text {
  h4 {
    color: $primary-red;
    margin: auto;
    padding-bottom: 30px;
    text-align: center;
  }
}

.card-text:last-child {
  height: 100px;
  margin-bottom: 0;
  width: 100%;
  text-overflow: ellipsis;
  margin-top: 12px;
  overflow: hidden;
}

.card-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 135%;
  letter-spacing: 0.01em;
}

.text-muted {
  color: $gray-medium-custom;
  font-size: 13px;
  line-height: 135%;
  font-style: italic;
}

#create-reviews,
#index-reviews,
#edit-reviews,
#show_reviews {
  h4 {
    font-family: 'Arial Black', 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: 0.01em;
    color: $primary-red;
  }
}

.container-hero__content {
  position: relative;
  top: 30%;
  padding: 5px 40px 5px 40px;
  @media (max-width: 769px) {
    padding: 5px 30px;
  }
}

// imported pages
.item.bgi {
  width: 100% !important;
  .container {
    h1 {
      text-align: left !important;
    }
  }
}

.container_reviews {
  padding: 4rem 4rem;
  @media (max-width: 769px) {
    padding: 0;
  }
}

.social-media-icon {
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
}

.social-media-icon:hover {
  background: $primary-red;
}

#FAQs {
  h4 {
    color: $primary-red;
  }
}

#public-faqs-body {
  .card {
    border: 1px solid #b8b8b8 !important;
    border-radius: 15px !important;
    margin: 10px 5px !important;
    padding: 8px !important;
    color: black !important;

    &:hover {
      background-color: #b8b8b81c !important;
      cursor: pointer;
    }
  }
  .btn {
    font-size: 12px !important;
  }
  .btn-group {
    padding: 10px 15px;
    margin: 10px 0px;
    background-color: #f8e8ea;
    border-radius: 20px;
    overflow-x: scroll;
    @include scrollbar(rgba(204, 116, 128, 0.31), transparent);
  }
  .card-header {
    font-weight: 900;
  }
  .card-body {
    padding: 5px 15px 5px 15px !important;
  }
  .accordion .card .card-header {
    color: black !important;
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    margin: 0px 12px 0px 0px;
  }

  .btn-primary {
    background: $white;
    border: 1px solid $primary-red !important;
    border-radius: 10px !important;
    color: $primary-red !important;
    text-transform: capitalize !important;
  }

  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: $primary-red !important;
    color: $white !important;
    font-weight: 900 !important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.formReserva {
  background: transparent !important;
}

// DATA TABLES STYLES
.rdt_Pagination {
  justify-content: center;
}
.rdt_TableHead {
  font-weight: bold;
}
.rdt_TableRow:hover {
  background: #f7e8ea !important;
  color: #aa182c !important;
}
.rdt_TableRow:nth-child(odd) {
  background-color: #f8f9fa;
}
.rdt_TableRow {
  font-size: 11px;
}

.custom-data-table {
  .btn-outline-info {
    color: darkgray !important;
    border-color: darkgray !important;
    height: 30px !important;
    width: 30px !important;
    font-size: 10px;
  }
  .btn-outline-info:hover {
    color: white !important;
    background: #aa182c;
    border-color: #aa182c !important;
  }
  .btn-outline-warning {
    color: darkgray !important;
    border-color: darkgray !important;
    height: 30px !important;
    width: 30px !important;
    font-size: 10px;
  }
  .btn-outline-warning:hover {
    color: white !important;
    background: #aa182c;
    border-color: #aa182c !important;
  }
  .btn-outline-danger {
    color: #aa182c !important;
    border-color: #aa182c !important;
    height: 30px !important;
    width: 30px !important;
    font-size: 10px;
  }
  .btn-outline-danger:hover {
    color: white !important;
    background: #aa182c;
    border-color: #aa182c !important;
  }
}

// Esta propiedad aplica al menu de los input selector menu desplegable
.css-26l3qy-menu {
  z-index: 100000 !important;
}

.fixed-button {
  position: fixed;
  right: 5px;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px !important;
  height: 150px !important;
  z-index: 3000;

  @media (max-width: 767px) {
    right: 20px;
    width: 120px !important;
    height: 120px !important;
  }
  img {
    width: 100%;
    height: auto;
  }
}
