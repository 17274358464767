.cargo-banner {
  height: 320px;
  margin-bottom: 100px;
}

.cargo-banner-image {
  width: 40%;
  height: 100%;
  background: gray;
}

.cargo-banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cargo-banner-content {
  width: 60%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 100px;
}

.cargo-banner-btn {
  margin-left: auto;
  border-radius: 10px !important;
}

@media (max-width: 992px) {
  .cargo-banner {
    height: 500px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
  }
  .cargo-banner-image {
    width: 100%;
    height: 45%;
  }
  .cargo-banner-content {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
  }
  .cargo-banner-btn {
    margin-left: 0px;
  }
}
