.our-terminals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;

  .our-terminals-title {
    font-size: 32px;
    font-weight: 900;
  }
  .our-terminals-description {
    font-size: 13px;
    font-weight: 400;
  }
  .our-terminals-cards-container {
    .our-terminals-card {
      height: 300px;
      width: 100%;
      overflow: hidden;
      border-radius: 30px;
      overflow: hidden !important;
      display: flex;
      align-items: end;
      background-size: 125%;
      background-position: center center;
      margin-bottom: 2rem;

      .our-terminals-info {
        background: linear-gradient(
          transparent 0%,
          transparent 0%,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 1)
        );
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 10px;
        overflow: hidden !important;
      }
      .our-terminals-title {
        color: white;
        font-size: 17px;
        font-weight: bold;
      }
      .our-terminals-description {
        color: white;
        font-size: 14px;
        max-width: 100%;
        min-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .our-terminals-button {
        margin: 10px 0;
        transition: all 0.3s ease-out;
      }
      .our-terminals-button:hover {
        padding: 12px 0;
      }

      .our-terminals-image-container {
        background: orange;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: -100 !important;
      }
    }
  }
}
