#cc-main {
  /** Change font **/
  --cc-font-family: Roboto;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #aa182c;
  --cc-btn-primary-border-color: #ffffff;
  --cc-btn-primary-hover-bg: #8f0b1d;
  --cc-btn-primary-hover-border-color: #ffffff;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 10px;

  /** Change modal fonts andborder radius styles **/
  --cc-font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --cc-modal-border-radius: .5rem;
  --cc-btn-border-radius: .4rem;
  --cc-modal-transition-duration: .25s;
  --cc-modal-margin: 1rem;
  --cc-z-index: 2147483647;
}