.profile-user-edit {
  .form-control:disabled {
    background: #dee2e6 !important;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .profile-edit-header-btn {
    font-size: 12px;
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 10px;

    @media (max-width: 992px) {
      display: none;
    }
  }
}
.custom-file {
  min-width: 200px !important;
}
.avatar-profile-form-image {
  width: 200px !important;
  height: 200px !important;
  border-radius: 50% !important;
  overflow: hidden;
  margin: auto;
  @media (max-width: 767px) {
    margin-top: -20px;
    width: 150px !important;
    height: 150px !important;
  }
}

.custom-breadcrumb {
  .breadcrumb {
    background-color: transparent !important;
    padding: 0;
  }
  .breadcrumb-item {
    font-weight: 600;
    margin-bottom: 10px !important;
    a {
      color: gray !important;
      text-decoration: none;
    }
    &.active {
      color: #aa182c !important;
    }
    &::before {
      color: #aa182c !important;
    }
  }
  li {
    margin-top: 0 !important;
  }
  ol {
    margin-top: 15px !important;
  }
}
