.centered-modal {
  .modal-header {
    border: none;
    margin-bottom: -30px;
  }
}

.modal-header {
  border-bottom: none !important;
}

.modal-content {
  border-radius: 2rem;
}

.modal {
  background: rgba(0, 0, 0, 0.3);
}
