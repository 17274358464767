.videosandguides-container {
  padding-top: 150px;
  min-height: 100vh;
}
.videosandguides-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.videosandguides-selector {
  display: flex;
  background: #f7e8ea;
  padding: 10px;
  border-radius: 15px;
}

.videosandguides-btn {
  padding: 5px 10px !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease-in-out;
}
