.page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .spinner {
    margin-right: 10px;
  }

  .page-content {
    text-align: justify;
    width: 100% !important;

    @media (min-width: 992px) {
      margin-top: 120px;
    }

    &.hide-subheader {
      margin-top: 0px !important;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    p {
      font-size: 18px;
    }

    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 24px;
    }

    h5 {
      font-size: 20px;
    }

    h6 {
      font-size: 18px;
    }
  }
}