.dashboard {
  padding: 10px 0 10px 0;

  .dashboard-piepedrive-card {
    border-radius: 20px;
    background: #e6f1eb;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    transition: all 0.4s ease-in-out;
    border: 1px solid #e6f1eb;
    cursor: pointer;

    p {
      transition: all 0.4s ease-in-out;
      font-size: 60px;
      font-weight: bold;
      color: #017737 !important;
      letter-spacing: -3px;
    }
  }
  .dashboard-piepedrive-card:hover {
    background: #caedda;
    transition: all 0.4s ease-in-out;
    border: 1px solid #017737;

    p {
      transition: all 0.4s ease-in-out;
      font-size: 64px;
      color: #017737 !important;
    }
  }

  .dashboard-googleAnalytics-card {
    border-radius: 20px;
    background: #fcf1e5;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    border: 1px solid #fcf1e5;
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    img {
      transition: all 0.4s ease-in-out;
      width: 55%;
    }
  }
  .dashboard-googleAnalytics-card:hover {
    transition: all 0.4s ease-in-out;
    background: #ffe9d2;
    border: 1px solid #e37300;

    img {
      transition: all 0.4s ease-in-out;
      width: 58%;
    }
  }

  .custom-pagination-component {
    display: none;
  }
  header {
    display: none;
  }
  .rdt_TableHead {
    font-weight: bold;
    font-size: 12px;
  }

  .rdt_TableRow {
    font-size: 12px !important;
    letter-spacing: -0.5px;
  }
  .rdt_TableRow:hover {
    background: #f7e8ea !important;
    color: #aa182c !important;
  }
  .rdt_TableRow:nth-child(odd) {
    background-color: #f8f9fa;
  }
  .custom-data-table {
    min-height: 310px !important;
  }

  .no-styles {
    color: inherit;
    text-decoration: none;
  }
}
