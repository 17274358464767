.form-book-trip {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  .form-book-trip-col {
    padding-left: 0;
    padding-right: 0;
  }

  .reserve-btn-container {
    padding: 0;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 0 !important;

    @media (max-width: 767px) {
      margin-top: 20px;
      padding-left: 0;
    }
    .reserve-btn {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .reserve-btn:disabled {
      background-color: grey !important;
      cursor: auto !important;
      opacity: 0.5 !important;
    }
  }
}
