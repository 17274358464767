h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: calc(var(--project-font-size) * 4.2);
  letter-spacing: calc(var(--project-font-size) * 3 * 0.02);
  line-height: calc(var(--project-font-size) * 3 * 1.35);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 115%;
  letter-spacing: 0.02em;
  color: $black-custom;
  @media (max-width: 575.98px) {
    font-size: calc(var(--project-font-size) * 2.6);
  }
}
h2 {
  font-size: calc(var(--project-font-size) * 3);
  letter-spacing: calc(var(--project-font-size) * 2.5 * 0.01);
  line-height: calc(var(--project-font-size) * 2.5 * 1.3);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: $black-custom;
}
h3 {
  font-size: calc(var(--project-font-size) * 2);
  letter-spacing: calc(var(--project-font-size) * 2 * 0.03);
  line-height: calc(var(--project-font-size) * 2 * 1.3);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: $black-custom;
}
h4 {
  font-size: calc(var(--project-font-size) * 1.8);
  letter-spacing: calc(var(--project-font-size) * 1.8 * 0.02);
  line-height: calc(var(--project-font-size) * 1.8 * 1.35);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: $black-custom;
}
h5 {
  font-size: calc(var(--project-font-size) * 1.5);
  letter-spacing: calc(var(--project-font-size) * 1.5 * 0.02);
  line-height: calc(var(--project-font-size) * 1.5 * 1.35);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.02em;
  color: $black-custom;
}
h6 {
  font-size: calc(var(--project-font-size) * 1.2);
  letter-spacing: calc(var(--project-font-size) * 1.2 * 0.04);
  line-height: calc(var(--project-font-size) * 1.2 * 1.35);
  font-family: 'Arial Black', 'Arial';
  font-style: normal;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: $black-custom;
}

p {
  font-size: calc(var(--project-font-size) * 0.8125);
  letter-spacing: calc(var(--project-font-size) * 0.8125 * 0.09);
  line-height: calc(var(--project-font-size) * 0.8125 * 1.5);
  margin: 0;
  font-weight: 200;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: $black-text;
}

.subtitle {
}
