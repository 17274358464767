.navbar {
  border-radius: 0 !important;
  min-height: 56px !important;
  border: none !important;
  position: fixed !important;
}
.navbar-brand {
  margin-right: 16px;
  border: 0 !important;
}
.navbar-collapse {
  padding-left: 0px !important;
  padding-right: 0px !important;
  box-shadow: none !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-nav {
    float: none !important;
    margin: 0;
  }
}
.navbar-red {
  background-color: rgba(158, 31, 44, 0.95) !important;
  background-blend-mode: soft-light !important;
  backdrop-filter: blur(5px);
  padding: 0 15px !important;
  .navbar-brand-red {
    padding: 0 0 !important;
    height: 40px !important;
    img {
      z-index: 10001;
      position: relative;
      height: 95px;
      margin-top: -25px;
      border-radius: 5px;
    }
    @media (max-width: 991px) {
      img {
        margin-top: -30px;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 6px 15px !important;
  }
}
.navbar-black {
  background-color: #1b1b1b !important;
  background-blend-mode: soft-light !important;
  backdrop-filter: blur(5px);
  padding: 0 15px !important;
  .navbar-brand-black {
    padding: 0 0 !important;
    height: 40px !important;
    img {
      z-index: 10001;
      position: relative;
      height: 95px;
      margin-top: -25px;
      border-radius: 5px;
    }
    @media (max-width: 991px) {
      img {
        margin-top: -30px;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 6px 15px !important;
  }
}

.btn-box-navbar {
  gap: 10px;
  margin: 5px 0;
}

.navbar-transparent {
  background-color: transparent !important;
  backdrop-filter: none !important;
  color: $white !important;
}

.navbar-collapse {
  z-index: 100000 !important;
  @media (max-width: 991px) {
    text-align: center;
  }
}

.navbar-light .navbar-toggler {
  color: white !important;
  border: 1px solid $white !important;
  margin-left: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
  padding: 7px 12px !important;
  border-radius: 30px;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: var(--project-font-family) !important;
  transition: all 0.3s;

  &:focus {
    color: white !important;
    background: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    color: white !important;
    background: rgba(255, 255, 255, 0.2);
  }
}

.navbar-link-active {
  color: white !important;
  background: rgba(255, 255, 255, 0.2);
}
.navbar-collapse {
  .language-buttons-on-small-screeen {
    display: none;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .navbar-nav {
      padding-top: 3rem;
      width: 100%;
      margin-bottom: 10px !important;
      .nav-link {
        margin-bottom: 1rem;
        font-size: 20px !important;
        font-weight: bold !important;
        color: red;
      }
    }
    .language-buttons {
      display: none !important;
    }
    .language-buttons-on-small-screeen {
      margin-top: 5vh;
      display: block;
    }
  }
}
