.image-modal {
  background: rgba(0, 0, 0, 0.9);
  .modal-content {
    background: transparent;
  }
  .close {
    span {
      color: red !important;
    }
  }
  .modal-content .modal-header .close span {
    color: white !important;
  }
  .close {
    font-size: 40px;
  }
}

.image-with-zoom-image-size {
  max-width: 60%;
  @media (max-width: $md) {
    max-width: 100%;
  }
}
