.image-and-text-component {
  border-radius: 15px;
  background-color: white;

  .title {
    font-size: 2rem;
  }
  p {
    text-align: justify;
  }

  @media (max-width: 991px) {
    padding: 5vh 3vw;

    .col-right {
      padding: 10%;
      .btn-mobile {
        padding: 0.8rem;
        border: 1px solid white;

        &.white {
          background-color: white;
          color: black;
        }
      }
    }
  }

  .our-ferries-image-container {
    height: 300px;

    .main-img {
      height: 300px;
      border-radius: 15px;
      object-fit: cover;
    }
    @media (max-width: 991px) {
      max-width: 90%;
    }
  }
}

.our-ferries-characteristics {
  background-color: $gray-light-custom;

  .our-ferries-characteristics-card {
    max-width: 265px;
    max-height: 265px;
    border-radius: 20px;
    margin-bottom: 2rem;
    background-color: $white !important;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;

    .card-image-container {
      border-radius: 20px;
      height: 265px;
      height: 265px;
      object-fit: cover;
    }
    .card-title-text {
      font-size: 15px;
      font-weight: 700;
      color: $white;
    }

    .card-body-category-text {
      margin-top: 5px;
      font-size: 13px;
      font-weight: 400;
      color: $white;
    }

    .card-body-opacity {
      opacity: 0.6;
      min-width: 100%;
      border-radius: 20px;
      position: absolute;
      background-color: $black;
      min-height: 265px;
    }

    .card-body {
      opacity: 1;

      height: auto !important;
      min-height: 50px !important;
      max-height: 250px !important;

      position: absolute;
      bottom: -65px;
      z-index: 10;
      background-color: transparent;
      width: 100%;

      @media (max-width: 991px) {
        height: auto !important;
        min-height: 50px !important;
        max-height: 250px !important;
        opacity: 1;
        position: relative;
        bottom: 100px;
        visibility: visible;
      }
    }
  }
}

.card-body-placeholder {
  width: 265px !important;
  height: 265px !important;
  background-color: gray;
  border-radius: 20px;
  z-index: 20;
}

.our-ferries-description-text {
  font-size: 15px;
  font-weight: 400;
}

// RATES CONTENT BOX

.facilities-content-box {
  @media (max-width: 992px) {
    padding: 0 5%;
  }
}
.facilities-div {
  max-width: 100%;
  padding: 20px;
}

.facilities-header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.facilities-header-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.5%;

  img {
    height: 30px;
  }
}

.facilities-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.facilities-row-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  width: 12, 5%;
  background: #f7e8ea;
  padding: 20px 4px;
  text-align: center;
}

.facilities-row-item-light-red {
  background: #fbf3f5;
}
.facilities-row-item-red {
  background: $primary-red-10;
}

.facilities-border-radius-left {
  border-radius: 15px 0 0 15px;
}
.facilities-border-radius-right {
  border-radius: 0 15px 15px 0;
}

.carousel-facilities {
  height: 90%;

  .img-facilities-section {
    height: 100%;
  }
  @media (max-width: 991px) {
    display: none;
  }
}

.carousel-facilities-map {
  height: 300px;

  .img-map-section {
    height: 300px;
  }
  @media (max-width: 991px) {
    // display: none;
  }
}
