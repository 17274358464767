.cargo-rates-content-box {
  padding: 0 15%;
  @media (max-width: 992px) {
    padding: 0 5%;
  }
}
.cargo-rates-div {
  min-width: 980px;
  padding: 20px;
}

.cargo-rates-title {
  font-size: 32px;
  line-height: 43px;
  font-weight: 900;
  color: $black !important;
}

.cargo-rates-div-scroll {
  overflow: scroll;
  scroll-behavior: smooth;
}

.cargo-rates-header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.cargo-rates-header-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 14.25%;

  img {
    height: 30px;
  }
}

.cargo-rates-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.cargo-container-top-border {
  // border-top: solid 1px red;
  border-radius: 0 0 15px 0;
}
.cargo-container-bottom-border {
  // border-bottom: solid 1px green;
  border-radius: 0 15px 0 0;
}

.cargo-container-top-bottom-border {
  // border-top: solid 1px red;
  // border-bottom: solid 1px green;
}

.cargo-rates-col {
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.cargo-rates-row-category-item {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background: #f7e8ea;
  padding: 20px 4px;
  text-align: center;
}

.cargo-rates-category-container-radius {
  border-radius: 15px 15px 15px 15px;
}

.cargo-rates-row-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  width: 12, 5%;
  background: #f7e8ea;
  padding: 20px 4px;
  text-align: center;
}

.cargo-row-gray {
  background: $gray-light-custom;
}
.cargo-row-lightgray {
  background: #f8f8f8;
}

.cargo-border-radius-left {
  border-radius: 15px 0 0 15px;
}
.cargo-border-radius-right {
  border-radius: 0 15px 15px 0;
}

.cargo-route-selector-container {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  overflow-x: auto;
  background: $gray-light-custom;
  border-radius: 30px;
}
.cargo-route-selector-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  padding: 10px 20px;
  cursor: pointer;
  background: white;
  color: $black;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-in, all 0.3s ease-out;

  &:hover {
    transition: all 0.3s ease-in, all 0.3s ease-out;
    background: $black;

    .cargo-route-selector-box-text {
      transition: all 0.3s ease-in, all 0.3s ease-out;
      color: white;
    }
  }
}
.cargo-route-selector-box-active {
  background: $black;
  transition: all 0.4s ease-in-out;
}
.cargo-route-selector-box-text {
  color: $black;
  font-weight: bold;
}

@media (max-width: 992px) {
  .container {
    width: 100% !important;
  }
}
