.cargo-contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 20px;
}

.cargo-contact-advisor-card {
  background: white;
}
