.category-filter {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #f7e8ea;
  border-radius: 20px;
  pointer-events: auto;
  z-index: 1000 !important;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 95vw;
    margin: auto;
    margin-bottom: 20px;
  }
}

.category-selector-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  background: white;
  color: #aa182c;
  font-weight: bold;
  margin-right: 10px;
  border: 1px solid #aa182c;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 767px) {
    width: 100%;
    margin: 5px 0 5px 0;
  }
}

.category-selector-box-active {
  background: #aa182c;
  transition: all 0.4s ease-in-out;
}
.category-selector-hover:hover {
  background: #f7e8ea;
  transition: all 0.4s ease-in-out;
}

.category-selector-box-text {
  color: #aa182c;
  font-weight: bold;
  white-space: nowrap;
  padding: 5px 15px;
  min-width: 150px;
}
