#index-admin-routes {
  h4 {
    font-family: 'Arial Black', 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: 0.01em;
    color: $primary-red;
  }
}

#edit-route {
  h4 {
    font-family: 'Arial Black', 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: 0.01em;
    color: $primary-red;
  }
}

#create-routes {
  h4 {
    font-family: 'Arial Black', 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: 0.01em;
    color: $primary-red;
  }
}

.nested-rates {
  .title-label-container {
    padding-top: 10px;
    margin-bottom: 5px;
  }
  .details-label-container {
    margin-bottom: 5px;
  }

  .title-label {
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.01em;
  }
  .details-label {
    font-weight: 550;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: 0.01em;
  }
  .nested-row-form-container {
    border-radius: 10px;
    background-color: whitesmoke;
    margin: 10px;
    margin-bottom: 20px;
  }

  margin-top: 10px;
}
