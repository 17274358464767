.sidebar {
  background-color: $primary-red;
  padding-top: 0px;
  height: 100vh;
  overflow-y: scroll !important;
  scrollbar-width: thin !important;
  scrollbar-color: transparent transparent !important;
  width: 230px;
  position: fixed;
  transform: translateX(0px);
  transition: 0.4s transform ease-in-out;
  z-index: 1020;
  &.hidden-sidebar {
    transform: translateX(-410px);
    transition: 0.4s transform ease-in-out;
    @include desktop {
      transform: translateX(0px);
    }
  }
  .container-links {
    margin: 0px;
    min-height: 100vh;

    .navbar-brand {
      margin-left: 19px;
    }

    .nav-link {
      color: white;
      display: flex;
      align-items: center;
      padding: 5px 5px;

      &.active {
        color: white;
        background-color: #ffffff50;
        // border-right: 7px solid #ffffff90;
      }
      &:hover {
        color: white;
        background-color: #ffffff30;
        border-right: 7px solid #ffffff70;
      }
    }
  }
}
.hamburger-menu {
  border-color: $gray-400;
  background-color: transparent;
  @include desktop {
    display: none !important;
  }
  .icomoon {
    color: $gray-600;
  }

  &:hover,
  :hover {
    border-color: transparent;
    color: white;
    .icomoon {
      color: white;
    }
  }
}
.overlay {
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 1019;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.4s;
  @include desktop {
    display: none;
  }
}
.overlay-hidden {
  opacity: 0;
}

.accordion {
  .card {
    color: white;
    padding: 0px;
    transition: all 0.3s;
    background: transparent !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    &:focus {
      color: white;
      background: rgba(255, 255, 255, 0.2) !important;
      border-right: 7px solid #ffffff90 !important;
    }
    &:hover {
      color: white;
      background: rgba(255, 255, 255, 0.2) !important;
      border-right: 7px solid #ffffff90 !important;
    }
    .card-header {
      background: none;
      padding: 0px;
      border-bottom: 0px !important;
      background: transparent !important;
      .nav-link {
        border-right: 0px !important;
      }
    }
  }

  .sidebar .container-links .nav-link {
    background-color: none;
    border: 0px;
  }

  .card-body {
    min-height: 100%;
    max-height: 1000px;
    padding: 0.5rem 1.25rem;
  }
}

.accordion-sub-item {
  font-size: 13px !important;
  font-weight: bold;
  padding: 5px 5px !important;
  margin-left: 15px;
  border-radius: 20px !important;
  margin-bottom: 4px;
  letter-spacing: -0.3px;
  transition: all 0.2s ease-in-out;

  &:focus {
    background: $white !important;
    color: $primary-red !important;
    padding: 5px 5px !important;
    border-radius: 20px !important;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background: $white !important;
    color: $primary-red !important;
    padding: 5px 5px !important;
    border-radius: 20px !important;
    transition: all 0.2s ease-in-out;
  }
}
.accordion-sub-item-selected {
  background: $white !important;
  color: $primary-red !important;
}
.accordion-header {
  font-size: 14px !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
}
.chevron-down-icon {
  margin-left: auto !important;
}
.accordion-header-title {
  margin-right: auto !important;
}
