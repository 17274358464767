.cargo-lead-form-container {
  align-items: center;
  background-color: $white;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px;
  max-width: 70%;
  padding: 6vh 0 5vw 1vw;
  overflow: hidden;
  @media (max-width: 768px) {
    max-width: 85%;
    padding: 3rem;
  }

  .form-title {
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
  }

  .form-description {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  }

  .form-subtitle {
    font-family: Arial !important;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
  }

  .form-question {
    font-weight: 400 !important ;
    font-size: 15px !important;
    line-height: 20px !important;
    color: $black-text !important;
  }

  .form-italic {
    font-weight: 400 !important ;
    font-size: 11px !important;
    font-style: italic;
    line-height: 14px !important;
    color: $black-text !important;
  }

  .card-cargo-categories {
    max-height: 150px !important;
    min-height: 110px !important;
    width: 30% !important;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;
    background-color: $white !important;
    justify-content: center !important;
    overflow: 'hidden';
    border-radius: 10px;
    @media (max-width: 991px) {
      width: 45% !important;
    }

    &:hover {
      background-color: $gray-light-custom !important;
      border: 1px solid $gray-medium-custom !important;
    }
  }

  .selected-category {
    background-color: $gray-light-custom !important;
    border: 1px solid $gray-medium-custom !important;
  }
}

.card-cargo-body-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: flex-start;
  margin-left: 10px;

  .card-cargo-categories-image {
    height: 50px;
  }

  .card-cargo-categories-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  }
}

.three-columns {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.cargo-leads-custom-hero {
  height: 40vh !important;
  margin-bottom: 30px;
}

.cargo-leads-checkbox {
  .custom-checkbox {
    .custom-control-label {
      padding-top: 5px;
    }
  }
}

.remove-padding-left {
  padding-left: 0px;
}

.cargo-lead-main-form-container {
  display: flex;
}

.cargo-lead-input-radio-label {
  &.form-label {
    margin-bottom: 10px !important;
  }
}
