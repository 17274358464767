.transport-section {
  position: relative; /* Asegura que el contenido esté apilado sobre el pseudo-elemento */
  padding: 50px;
  min-height: 300px;
}

.transport-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Ajusta la opacidad según tu preferencia */
}

.lead-form {
  background: white;
  border-radius: 20px;
  width: 100%;
  height: 80%;
}

.guide-image {
  height: 200px;
}

.see-transport-btn {
  color: white;
  background: black !important;
  border: 1px solid white !important;
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.see-transport-btn:hover {
  background: red !important;
}

.lead-new-form {
  background: white;
  width: 90%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
