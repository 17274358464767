.profile {
  padding: 20px;

  .profile-title {
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
    }
  }

  .header-buttons {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .bottom-buttons {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .profile-header-btn {
    font-size: 12px;
    margin-left: 10px;
    padding: 10px 30px;
    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }
  }

  .profile-avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 992px) {
      justify-content: flex-end;
    }
  }

  .profile-info-container {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  }

  .profile-image-container {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: lightgray;
    margin-right: 20px;
  }
  .profile-image {
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    object-fit: cover;
  }
  .profile-image-placeholder {
    min-width: 70%;
    min-height: 70%;
    background-size: cover;
  }
}

.profile-close-session-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 5%;

  p {
    font-size: 20px;
    margin-bottom: 30px;
    color: black !important;
    font-weight: bold;
  }
}
.profile-close-session-modal-back-btn {
  width: 60%;
  border-radius: 10px !important;
  background: #ced4da !important;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.profile-close-session-modal-close-btn {
  width: 38%;
  border-radius: 10px !important;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
