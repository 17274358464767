.carousel-home {
  margin: 0 -15px;
  width: auto;
}
.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}

.carousel-component {
  border-radius: 15px;

  .img-carousel {
    border-radius: 15px;
  }
  .carousel-indicators {
    bottom: -50px;
    align-items: end;

    li {
      background-color: transparent;
      border: 1px solid $primary;
      height: 20px !important;
      width: 20px !important;
      border-radius: 50%;

      &.active {
        background-color: $primary;
      }
    }
  }
  &.hidden-arrow {
    .carousel-control-prev,
    .carousel-control-next {
      display: none !important;
    }
  }
}
