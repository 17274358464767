// Default Font Styles
:root {
  --project-font-size: 1rem;
  --project-font-family: Arial, Helvetica, sans-serif !important;
}

// Media queries
$sm: 570px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// All colors
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #01a93a !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Custom Colors
$primary-red: #aa182c !default;
$primary-red-dark: #8f0b1d !default;
$primary-red-60: #cc7480 !default;
$primary-red-30: #eed1d5 !default;
$primary-red-10: #f7e8ea !default;
$black-custom: #1b1b1b !default;
$black-text: #494949 !default;
$gray-medium-custom: #919191 !default;
$gray-light-custom: #f1f1f1 !default;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Default Colors
$primary: $primary-red !default;
$secondary: $primary-red-60 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$cancel: $gray-light-custom;
