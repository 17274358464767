.gallery-section {
  width: 100%;
  padding-bottom: 100px;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10% 1.5%;
  }

  .grid-item {
  }
}

.gallery-card-col {
  width: 90% !important;
  margin: 0;
  padding: 0;
}

.gallery-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}
.gallery-info {
  background: #f1f1f1;
  height: 180px;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  overflow: hidden;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
}

.gallery-title {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  min-height: 50px;
}
