@mixin standard-label {
  letter-spacing: normal;
  position: absolute;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: white;
  color: black;
  padding: 0.25rem 1rem;
  z-index: 1;
  border-radius: 1rem;

  &.focused {
    color: $dark;
  }
}

.font-weight-500 {
  font-weight: 500 !important;
}

input {
  font-size: 16px !important;
  font-family: Arial !important;
  border-radius: 10px !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.296) !important;

  @media (max-width: 600px) {
    font-size: 16px !important;
  }
}

@for $i from 1 through 10 {
  #react-select-#{$i}-input {
    height: 20px !important;
    font-family: Arial !important;
  }
}

abbr {
  font-family: Arial !important;
  font-size: 14px !important;
}

.form-control:focus {
  border: 1px solid $primary !important;
  box-shadow: 0px 0px 3px 2px #dc354658 !important;
}

input:invalid,
.is-invalid {
  animation: shake 300ms;

  &:focus {
    border-color: #dc354658 !important;
    box-shadow: 0px 0px 3px 2px #dc354658 !important;
  }

  // for remote select
  .css-12xdsrl-control,
  .css-52rfuw-control {
    border-color: #dc3545;

    &:hover {
      border-color: #dc3545 !important;
    }
  }

  .css-8fm3v1-control {
    box-shadow: 0px 0px 3px 2px #dc354658 !important;
    border-color: #dc354658 !important;
  }
}

// for remote select
.css-8fm3v1-control {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.296) !important;
}

.css-1g6gooi,
.css-b8ldur-Input {
  input {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
  }
}

//placenolder{
.css-g1d714-ValueContainer {
  padding: 0 8px !important;

  .css-1uccc91-singleValue,
  .css-1wa3eu0-placeholder {
    font-size: 16px !important;
    font-family: Arial !important;
  }
}

.formik-input-radio {
  margin-bottom: 1rem;

  label {
    @include standard-label();
    position: relative;
    margin: 0;
    padding: 0;
    top: 0.75rem;
  }
}

.form-radio-input {
  --radio-width: 1rem;

  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;

  .form-check-input {
    display: none;
  }

  .form-check-label {
    @extend p;
    @extend .font-weight-500;
    margin-right: 2rem;
  }

  .custom-radio-label-container {
    height: calc(var(--radio-width) + 10px);
  }

  .custom-radio-label {
    border: 2px solid $primary;
    min-width: 17px;
    min-height: 17px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-radio-label.checked::before {
    display: flex;
    content: '';
    width: calc(var(--radio-width) / 2);
    height: calc(var(--radio-width) / 2);
    background-color: $primary;
    border-radius: 50%;
  }
}

.two-columns {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.three-columns {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.small-screen-responsiveness {
  @media (max-width: 991px) {
    flex-direction: column;
    display: flex;
  }
}

.formik-switch {
  display: flex;
  align-items: center;
  justify-content: end;
  .form-checkbox-switch {
    margin-left: 10px;
  }
}
