.our-leaders-content-box {
  padding: 0 15%;

  @media (max-width: 1220px) {
    padding: 0 5%;
  }
}

.our-leaders-characteristics-card {
  border-radius: 20px;
  margin-bottom: 2rem;
  background-color: $white !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;

  .card-image-container {
    border-radius: 20px 20px 0px 0px;

    object-fit: cover;
  }
  .card-name-text {
    font-size: 18px;
    font-weight: 900;
    color: $white;
  }

  .card-occupation-text {
    font-size: 15px;
    font-weight: 400;
    color: $white;
  }

  .card-profession-text {
    font-size: 13px;
    font-weight: 700;
    color: #aa182c;
  }

  .card-school-name-text {
    font-size: 13px;
    font-weight: 400;
    color: #aa182c;
  }

  .card-goals-text {
    font-size: 13px;
    font-weight: 400;
    color: $black;
    text-align: justify;
  }

  .card-body {
    background-color: $white;
    padding: 0px 15px 0px 15px;
    min-height: 300px;
    height: auto !important;
    border-radius: 0px 0px 20px 20px;
  }

  .our-leader-name-occupation-container {
    background-color: #aa182c;
    height: 80px;
  }
}

.our-leaders-description-text {
  font-size: 15px;
  font-weight: 700;
}
