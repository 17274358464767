.dorpdown-data-download {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary;
  border-radius: 10px;

  .btn {
    color: $primary !important;
    width: 100%;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  .dropdown-menu.show {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
  }
}
