.vertical-dashed-line {
  border-left: 2px dashed $black;
  margin: auto;
  width: 100%;
  min-height: 40px;
}

.cargo-availability-steps-map {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  &--step {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-light-custom !important;
    border: 1px solid var(--step-color-filled) !important;
    color: $gray-medium-custom !important;
    font-size: 23px;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    box-shadow: none !important;
  }

  &--step.filled {
    background-color: $black-custom !important;
    color: white !important;
  }
}
