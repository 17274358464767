.blog-section {
  width: 100%;
  padding-bottom: 60px;
  position: relative;
}

.blog-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
  margin-left: 5%;
  @media (max-width: 767px) {
    margin-left: 0;
  }
}

.blog-highlighted-info {
  position: absolute;
  background: linear-gradient(
    transparent 10%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9)
  );
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 15px;
  border-radius: 30px;
}

.blog-highlighted-title {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.blog-highlighted-description {
  color: white;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.blog-info {
  height: 100%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.blog-highlighted-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 30px 30px 30px 30px;
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 30px 30px 30px 30px;
}

.blog-date {
  font-size: 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-weight: lighter;
  font-style: italic;
}

.blog-title {
  font-size: 16px;
  font-weight: bold;
  color: #8f0b1d;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 45px;
}

.blog-button {
  margin: 10px 0;
  transition: all 0.3s ease-out;
}

.blog-description {
  font-size: 14px;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  height: 60px;
}

.slide-section {
  position: relative;
  height: 600px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 60px;
}

.container-hero__content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.date-range {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 10vw;
    input {
      padding: 5px;
      width: 100%;
    }
  }
}

.date-label {
  font-weight: bold;
}

.search-input {
  width: 300px;
  height: 35px;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
}

.search-button {
  margin-left: 1rem;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  list-style: none;
  @media (max-width: 767px) {
    li {
      font-size: 7px;
    }
  }
}

.pagination .page-link {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #e9e9e9;
  color: #333;
  border: none;
}

.pagination .page-item.active .page-link {
  background-color: #333;
  color: #fff;
}

.pagination .page-item.disabled .page-link {
  background-color: transparent;
  color: #999;
  cursor: not-allowed;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  border-radius: 5px;
}

.pagination .page-item:first-child .page-link {
  margin-right: 5px;
}

.pagination .page-item:last-child .page-link {
  margin-left: 5px;
}
