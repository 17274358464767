.datepicker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  @media (max-width: 767px) {
    margin-left: 0px;
    width: 95vw;
    margin: auto;
    margin-top: 20px;
  }
}
