.custom-file-img {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1 !important;
  border: 1px solid lightgray;

  img {
    position: absolute;
    object-fit: cover;
    width: auto;
    height: auto;
    max-height: 100% !important;
    min-height: 100% !important;
  }
  &:after {
    content: '';
    padding-bottom: 56.25%;
    display: block;
  }
}

.custom-square-img {
  height: 200px;
  width: 200px;
}

.dropzone {
  border: 2px dashed #aa182c;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  background: rgba(170, 24, 43, 0.25);
  transition: all 0.3s ease-in, all 0.3s ease-out;
  min-height: 320px;
  max-height: 320px;
  overflow-y: scroll;
}

.dropzone-active {
  border-color: green;

  background: rgba(0, 128, 0, 0.25);
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.multi-image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.multi-image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.multi-image-preview .image-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.delete-icon {
  position: absolute;
  top: 15px;
  right: -5px;
  cursor: pointer;
  color: red;
  z-index: 10;
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.multi-image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
