// Estilos para pantallas medianas y pequeñas
@media (max-width: 991px) {
  .videos-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

// Estilos para pantallas grandes
@media (min-width: 992px) {
  .videos-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }
}

.videos-section {
  margin-bottom: 20px;

  .video-info {
    padding: 10px;
    background: transparent !important;
  }

  .video-title {
    font-size: 16px;
    font-weight: bold;
  }

  .video-description {
    font-size: 12px;
    color: gray;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 1.5em;
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border-radius: 50% !important;
    border: none !important;
    background-color: #aa182c;
    background-color: #aa182c;
    border-radius: 0.3em;
    transition: all 0.4s;
  }
}

.video-player-container {
  border-radius: 20px !important;
  overflow: hidden !important;
}
