.rates-content-box {
  padding: 0 15%;
  @media (max-width: 992px) {
    padding: 0 5%;
  }
}
.rates-div {
  min-width: 980px;
  padding: 20px;
}
.rates-div-scroll {
  @media (max-width: 992px) {
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

.rates-header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.rates-header-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.5%;

  img {
    height: 30px;
  }
}

.rates-row-item-light-red {
  background: #fbf3f5 !important;
}
.rates-row-item-red {
  background: #f7e8ea !important;
}

.rates-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  transition: all 0.3s ease-in, all 0.3s ease-out;
}

.rates-row-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  width: 12, 5%;
  background: #f7e8ea;
  padding: 20px 4px;
  text-align: center;
}

.border-radius-left {
  border-radius: 15px 0 0 15px;
}
.border-radius-right {
  border-radius: 0 15px 15px 0;
}

.route-selector-container {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  overflow-x: auto;
  background: #f7e8ea;
  border-radius: 20px;
}
.route-selector-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  padding: 10px 20px;
  cursor: pointer;
  background: white;
  color: #aa182c;
  font-weight: bold;
  margin-right: 10px;
  border: 1px solid #aa182c;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}
.route-selector-box-active {
  background: #aa182c;
  transition: all 0.4s ease-in-out;
}
.route-selector-box-text {
  color: #aa182c;
  font-weight: bold;
}
.route-services-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .container {
    width: 100% !important;
  }
}

.route-services-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 180px;
  margin: 10px;
  min-height: 180px;
  padding: 0 15px 0px 10px;
  padding-top: 40px;
  border-radius: 20px;
  background: white !important;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 3rem;
  }

  p {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 15px;
    justify-content: center;
  }
}
.route-services-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 100px 0 50px 0;

  @media (max-width: 992px) {
    flex-direction: column;
    margin: 10px 0 10px 0;
  }
}
.route-services-card-item {
  width: 48%;
  border-radius: 20px;
  overflow: hidden;
  min-height: 260px;
  background-size: 106% 106%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;

  p {
    width: 60%;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.route-services-card-btn {
  border-radius: 10px !important;
}
