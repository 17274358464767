.cargo-lead-custom-steps-map {
  --step-color-filled: black;
  --step-color: #f1f1f1;

  display: flex;
  justify-content: space-between;

  &--dashed-line {
    position: absolute;
    border-top: 1px dashed $black;
    width: 70%;
    transform: translate(15.5%, 5px);
    @media (max-width: 630px) {
      width: 65%;
      transform: translate(20%, 5px);
    }
    @media (max-width: 480px) {
      width: 55%;
      transform: translate(25%, 5px);
    }
  }

  &--step {
    justify-content: center;
    align-items: center;
    background-color: var(--step-color) !important;
    border: 1px solid var(--step-color) !important;
    color: $gray-medium-custom !important;
    font-size: 16px;
    font-weight: 700px;

    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    box-shadow: none !important;
  }

  &--step.filled {
    background-color: var(--step-color-filled) !important;
    color: white !important;
    z-index: 100;
  }
}

.cargo-lead-step-container {
  position: relative;
  z-index: 1;
}
