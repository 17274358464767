.templates-grid-container {
  display: flex;
  justify-content: center;
}

.template-card {
  display: flex;
  flex-direction: column;
  margin: 5px;
  height: 740px;
  border-radius: 5px;
}

.template-image-container {
  border-radius: 5px;
}

.template-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.template-card-body {
  display: flex;
  flex-direction: column;
}
