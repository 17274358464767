.custom-pagination-component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 20px 0;

  .custom-pagination-component-page-item {
    background: #eed1d5;
    font-size: 10px;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
  }
  .custom-pagination-component-page-item:hover {
    background: #cc7480;
    color: white;
    transition: all 0.4s ease-in-out;
  }
  .page-item-active {
    background: #aa182c;
    color: white;
    transition: all 0.4s ease-in-out;
  }
  p {
    color: lightgray;
  }
  svg {
    cursor: pointer;
  }
}
