.btn {
  font-weight: 600;
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:hover,
  &:focus {
    filter: alpha(opacity = 80);
    opacity: 0.8;
  }

  &.btn-logout {
    background-color: transparent !important;
    border: none;
    margin: 5px 0 0 17px;
    &:hover {
      color: $dark;
    }
    &:focus,
    &:active {
      background-color: transparent !important;
      color: $warning !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  &-primary-outline {
    border-radius: 10px;
    border: 1px solid $primary;
    color: $primary;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-red-30 !important;
      color: $primary;
      opacity: 1;
    }
  }

  &-dark {
    background-color: $black-custom;
    border-radius: 10px;
  }
  &-transparent {
    border-radius: 10px;
  }

  &.non-clickable {
    pointer-events: none !important;
  }
}

.offices-btn {
  background: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05) !important;
  border-radius: 30px;
  border: 1.5px solid transparent;
  padding: 10px 25px !important;
  transition: all 0.3s;

  &:hover {
    border: 1.5px solid #ffffff !important;
  }
}

.main-navbar {
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .btn {
    margin-left: 0 !important;
    padding: 8px 25px !important;
    font-family: Arial !important;
    margin-bottom: 0 !important;
  }
  .btn-contact {
    background: rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05) !important;
    border-radius: 30px;
    border: 1.5px solid transparent;
    padding: 10px 25px !important;
    transition: all 0.3s;

    &:hover {
      border: 1.5px solid #ffffff !important;
    }
  }
  .btn-sm-outline-white {
    background: $primary-red;
    color: $white;
    border-radius: 10px;
    padding: 8px 25px !important;
    opacity: 1 !important;
    transition: all 0.3s;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center;
    letter-spacing: 0.085em;
    border: 1px solid $white !important;
    &:not(:disabled):not(.disabled):active {
      background-color: $primary-red-dark;
      border: 1px solid $white !important;
    }
    &:hover {
      background-color: $primary-red-dark;
      border: 1px solid $white !important;
    }
    &:focus {
      background-color: $primary-red-dark;
      border: 1px solid $white;
      box-shadow: none !important;
    }
    height: 34px !important;
    width: 135px !important;
  }

  .btn-link {
    color: $primary-red;
    padding: 5px;
    &:hover {
      color: $primary-red;
      background-color: $primary-red-10;
      border-radius: 10px;
      text-decoration: none;
    }
  }
  .btn-sm-white {
    background: $white !important;
    color: $black-custom !important;
    border: 1px solid $white !important;
    border-radius: 10px;
    padding: 8px 25px !important;
    opacity: 1 !important;
    transition: all 0.3s;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center;
    letter-spacing: 0.085em;
    height: 34px !important;
    width: 180px !important;
    display: flex;
    align-items: center;

    &:not(:disabled):not(.disabled):active {
      background-color: $black-custom !important;
      color: $white !important;
    }
    &:hover {
      background-color: $black-custom !important;
      color: $white !important;
      border: 1px solid $black-custom !important;
    }
    &:focus {
      background-color: $black-custom;
      color: $white;
      border: 1px solid $black-custom !important;
      box-shadow: none !important;
    }
  }

  .btn-esp,
  .btn-ing {
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
    padding: 0 !important;
    border-radius: 12px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    height: 33px;
    width: 50px;

    &:hover,
    &:focus {
      opacity: 1 !important;
    }

    &.btn-ing {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    &.btn-esp {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &.btn-active {
      background-color: black !important;
      color: white !important;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 2px;
    }
  }
}

.btn-switch-esp,
.btn-switch-ing {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  padding: 0 !important;
  border-radius: 12px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  height: 33px;
  width: 150px;

  &:hover,
  &:focus {
    opacity: 1 !important;
  }

  &.btn-switch-ing {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &.btn-switch-esp {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  &.btn-active {
    background-color: black !important;
    color: white !important;
  }
}

.box-icon-truck {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1); /* Firefox */
  -webkit-transform: scaleX(-1); /* Chrome - Safari */
  -o-transform: scaleX(-1); /* Opera */
}

.box-icon-calendar {
  margin-bottom: 4px;
}

.btn-red {
  background: $primary-red !important;
  border-radius: 10px;
  padding: 17px 35px !important;
  border: 0px;
  opacity: 1 !important;
  transition: all 0.3s;
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-red-dark !important;
  }

  &:hover {
    background-color: $primary-red-dark !important;
  }
  &:focus {
    background-color: $primary-red-dark !important;
    box-shadow: none !important;
  }
  &:disabled {
    background-color: $gray-medium-custom !important;
    color: $white;
  }
}

.btn-primary {
  background: $primary-red !important;
  border-radius: 10px !important;
  padding: 5px 25px;
  border: 0px !important;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out !important;
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-red-dark !important;
  }

  &:hover {
    background-color: $primary-red-dark !important;
  }
  &:focus {
    background-color: $primary-red-dark !important;
    box-shadow: none !important;
  }
  &:disabled {
    background-color: $gray-medium-custom !important;
    color: $white !important;
  }
}

.btn-cancel {
  background: $gray-light-custom;
  border-radius: 10px;
  padding: 5px 25px;
  border: 0px;
  opacity: 1 !important;
  transition: all 0.3s;
  &:not(:disabled):not(.disabled):active {
    background-color: $gray-medium-custom;
  }

  &:hover {
    background-color: $gray-medium-custom;
  }
  &:focus {
    background-color: $gray-medium-custom;
    box-shadow: none !important;
  }
}

#content-admin {
  .navbar-light .navbar-nav .nav-link {
    color: $primary-red;
    padding: 5px;
    &:hover {
      color: $primary-red;
      background-color: $primary-red-10;
      border-radius: 10px;
      text-decoration: none;
    }
  }
}
.nested-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.nested-form-delete-btn {
  background: transparent;
  fill: $primary !important;
  border: 1px solid $primary;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: $primary;
    fill: white !important;
    padding: 2px;
  }
}

.create-btn {
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
}

.drag-drop-btns {
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;

    .btn {
      width: 100% !important;
      margin: 0 !important;
      padding: 10px !important;
      margin-bottom: 10px !important;
    }
  }
}

.pages-and-blogs-btn {
  @media (max-width: 768px) {
    width: 90% !important;
    margin: auto !important;
    padding: 10px !important;
    margin-bottom: 10px !important;
  }
}

.btn-navbar-white {
  background: $white !important;
  color: $black-custom !important;
  border: 1px solid $white !important;
  border-radius: 10px;
  padding: 8px 25px !important;
  opacity: 1 !important;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center;
  letter-spacing: 0.085em;
  height: 34px !important;
  width: 180px !important;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:disabled):not(.disabled):active {
    background-color: gray !important;
    color: $white !important;
  }
  &:hover {
    background-color: gray !important;
    color: $white !important;
    border: 1px solid white !important;
  }
  &:focus {
    background-color: gray;
    color: $white;
    border: 1px solid white !important;
    box-shadow: none !important;
  }
  &:active {
    background-color: gray;
    color: $white;
    border: 1px solid white !important;
    box-shadow: none !important;
  }
}
