.public-faqs {
  z-index: 1000 !important;
  header {
    padding: 0;
    margin-bottom: 20px;
  }

  .rdt_TableHeadRow {
    display: none;
  }

  .rdt_TableRow {
    border-bottom-style: none;
    background-color: white !important;
    border-bottom: none !important;
  }

  .rdt_TableRow:hover {
    background-color: white !important;
  }

  .rdt_Pagination {
    display: none;
  }

  .question-and-answer-cell {
    border: 1px solid lightgray;
    padding: 10px 20px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .question-and-answer-cell-question {
    font-weight: bold;
    font-size: 16px;
  }

  .answer-show-btn {
    border: 2px solid #aa182c;
    padding: 5px;
    color: #aa182c;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s all ease-in-out;
  }

  .answer-show-btn-active {
    background: #aa182c;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s all ease-in-out;
  }

  .answer-show {
    height: auto;
    opacity: 1;
    padding-bottom: 10px;
    color: lightgray;
    transition: 0.4s all ease-in-out;
  }

  .answer-hide {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0px;
    color: white;
    transform: translateY(-10%);
    transition: 0.4s all ease-in-out;
  }

  .category-selector-container {
    width: 100%;
    display: flex;
    padding: 10px 20px;
    overflow-x: auto;
    background: #f7e8ea;
    border-radius: 20px;
    border-color: transparent;
    border-bottom: none;
    overflow: hidden;
  }

  .faqs-category-selector-container {
    width: 100%;
    display: flex;
    padding: 0 5px;
    overflow-x: auto;
    background: #f7e8ea;
    border-radius: 20px;
    border: 10px solid #f7e8ea;

    /* Ocultar la barra de desplazamiento */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer / Edge */
  }

  .faqs-category-selector-container::-webkit-scrollbar {
    display: none; /* Safari y Chrome */
  }

  .category-selector-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    cursor: pointer;
    background: white;
    color: #aa182c;
    font-weight: bold;
    margin-right: 10px;
    border: 1px solid #aa182c;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
  }

  .category-selector-box-active {
    background: #aa182c;
    transition: all 0.4s ease-in-out;
  }
  .category-selector-hover:hover {
    background: #f7e8ea;
    transition: all 0.4s ease-in-out;
  }

  .category-selector-box-text {
    color: #aa182c;
    font-weight: bold;
    white-space: nowrap;
    padding: 5px 15px;
    min-width: 150px;
  }
}
