.itinerary-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;
  padding: 6rem;
  background: #f1f1f1;

  .itinerary-section-title {
    font-size: 32px;
    font-weight: 900;
  }
  .itinerary-section-description {
    font-size: 13px;
    font-weight: 400;
  }
  .itinerary-section-cards-container {
    .itinerary-card {
      background: white;
      border-radius: 20px;
      padding: 3rem;
    }
  }
}
