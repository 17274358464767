.guide-section {
  width: 100%;
  padding-bottom: 200px;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Crea 3 columnas de igual tamaño */
    grid-gap: 4%; /* Espacio entre las celdas */
  }

  .grid-item {
  }
}

.guide-card-col {
  width: 90% !important;
  margin: 0;
  padding: 0;
}

.guide-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}
.guide-info {
  background: #f1f1f1;
  min-height: 290px;
  width: 100%;
  padding: 40px 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guide-card-image {
  max-height: 200px;
  max-width: 200px;
}

.guide-title {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  min-height: 50px;
}

.guide-description {
  font-size: 12px;
  min-height: 30px;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
