.animated-grid {
  height: 600px;
  width: 100%;
  margin-bottom: 50px;

  display: grid;
  gap: 1%;

  /* Explicit grid */
  grid-template-areas:
    'a  a  a  c  c  c  d  d  d'
    'a  a  a  c  c  c  d  d  d'
    'a  a  a  c  c  c  d  d  d'
    'a  a  a  c  c  c  d  d  d'
    'b  b  b  c  c  c  d  d  d'
    'b  b  b  c  c  c  d  d  d'
    'b  b  b  c  c  c  d  d  d'
    'b  b  b  c  c  c  d  d  d';

  grid-template-rows: repeat(9, 10%);
  grid-template-columns: repeat(9, 10%);

  --stagger-delay: 100ms;

  .card {
    background-color: #8f0b1d;
    animation: cardEntrance 700ms ease-out;
    animation-fill-mode: backwards;
    overflow: hidden;
  }

  .card:nth-child(1) {
    grid-area: a;
    animation-delay: calc(1 * var(--stagger-delay));
  }
  .card:nth-child(2) {
    grid-area: b;
    animation-delay: calc(2 * var(--stagger-delay));
  }
  .card:nth-child(3) {
    grid-area: c;
    animation-delay: calc(3 * var(--stagger-delay));
  }
  .card:nth-child(4) {
    grid-area: d;
    animation-delay: calc(4 * var(--stagger-delay));
  }

  .attraction-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .attraction-info {
    position: absolute;
    background: linear-gradient(
      transparent 10%,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.9)
    );
    bottom: 0;
    left: 0;
    height: 70%;
    min-height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 15px;
  }
  .attraction-title {
    color: white;
    font-size: 17px;
    font-weight: bold;
  }
  .attraction-description {
    color: white;
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .attraction-button {
    margin: 10px 0;
    transition: all 0.3s ease-out;
  }
  .attraction-button:hover {
    margin: 15px 0;
  }
}

@keyframes cardEntrance {
  from {
    opacity: 0;
    transform: scale(0.3);
    filter: hue-rotate(180deg);
  }
  to {
    opacity: 1;
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
}
