.guides-section {
  position: relative;
  padding: 20px;
  min-height: 500px;

  .guide-image {
    width: 150px;
  }
}

.guides-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.lead-form {
  background: white;
  border-radius: 20px;
  width: 100%;
  height: 80%;
}

.see-guides-btn {
  color: white;
  background-color: $black-custom !important;
  border: 1px solid white !important;
  transition: all 0.3s ease-in, all 0.3s ease-out;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 35px;
  padding-right: 35px;
}

.see-guides-btn:hover {
  background-color: $black !important;
}

.lead-new-form {
  background: white;
  width: 90%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 105%;
    margin-bottom: 20px;
  }
}

.guide-page .col-sm-10 {
  max-width: 100% !important;
  flex: none !important;
}
