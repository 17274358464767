.cargo-itinerary-card {
  max-height: 150px !important;
  max-width: 150px !important;
  color: $black-custom;
  transition: all 0.3s ease-in-out;

  .cargo-itinerary-card-body {
    height: 150px !important;
    width: 150px !important;

    &.card-body {
      min-height: 150px;
      max-height: 150px;
    }
  }

  .cargo-itinerary-date-text {
    font-weight: 900;
    font-size: 45px;
  }
  .cargo-itinerary-month-text {
    font-weight: 400;
    font-size: 13px;
  }
  .cargo-itinerary-port-text {
    font-weight: 700;
    font-size: 15px;
  }
}

.cargo-itinerary-card:hover {
  color: white;
  background: $black-custom !important;
  transition: all 0.3s ease-in-out;
}

.cargo-itinerary-selected-card {
  color: white !important;
  &.card{
    background-color: $black-custom!important;
  }
}

.card {
  &.cargo-deck-card {
    max-height: 110px !important;
    max-width: 250px !important;
    color: $black-custom;
    box-shadow: none !important;
    border: 1px solid $gray-medium-custom !important;

    .cargo-deck-text {
      font-weight: 700;
      font-size: 15px;
    }
    .cargo-deck-description-text {
      font-weight: 400;
      font-size: 13px;
    }
    .cargo-deck-bold-text {
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.cargo-availability-form-title {
  font-weight: 900;
  font-size: 18px;
  color: $gray-medium-custom;

  &.active {
    color: $black-custom;
  }
}

.cargo-availability-custom-hero {
  height: 40vh !important;
  margin-bottom: 30px;
}
