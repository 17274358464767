#show-guide {
  margin-top: 100px;
  margin-bottom: 100px;

  #public-guide-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
      font-size: 24px;
      font-weight: bold;
    }

    .back-btn {
      color: white;
      background: black;
      border: 1px solid white;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: red;
      }
    }
  }

  #public-guide-image {
    margin-bottom: 20px;

    .guide-image {
      width: 100%;
      height: auto;
      max-height: 500px;
      object-fit: cover;
    }
  }

  #public-guide-body {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: white;

    p {
      margin-bottom: 10px;
    }
  }
}
