// Common mixins

@mixin box-shadow {
  box-shadow: 3px 3px 10px 3px rgba(34, 34, 34, 0.15);
}

@mixin scrollbar(
  $main-color: rgba(68, 68, 68, 0.336),
  $scrollbar-color: rgba(53, 53, 53, 0.219)
) {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $scrollbar-color;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $main-color;
    border-radius: 5px;
  }
}

// Media Query Mixins
@mixin mobile {
  @media screen and (max-width: $md) {
    @content;
  }
}
@mixin mid-screens {
  @media screen and (min-width: $md) and (max-width: $xl) {
    @content;
  }
}

@mixin large-screens {
  @media screen and (min-width: $xl) and (max-width: $xxl) {
    @content;
  }
}

@mixin xl-screens {
  @media screen and (min-width: $xxl) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $lg) {
    @content;
  }
}
