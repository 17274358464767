.route-section {
  padding: 10vh 10%;
  background-color: #f1f1f1;
  h6,
  h5 {
    text-transform: none;
  }
  @media (max-width: 991px) {
    padding: 5vh 0;
  }
  .map-img {
    width: 22%;
    min-height: 620px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .route-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  .route-card-section {
    background-color: white;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;
    height: 100%;
    min-height: 580px;
    border-radius: 20px !important;

    @media (max-width: 991px) {
      min-height: auto;
    }

    border-radius: 12px;
    padding: 1rem;

    .subtitle {
      font-size: 1rem;
      font-weight: 700;
    }
    .content-icons {
      p {
        margin-bottom: 8px;
      }
      img {
        width: 22px;
      }
    }
    .container-btns {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: center;
        margin: 0 1rem;
      }
      .btn-card-section {
        min-width: 180px;
        @media (max-width: 991px) {
          min-width: 100%;
          margin-bottom: 5px;
        }
      }
      .remove-padding-container {
        padding: 0;
      }
    }
    .route-box-checkin {
      padding: 20px;
      background-color: #f1f1f1;
      border-radius: 30px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: auto;
    }

    .route-voyage-point {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: gray;
        margin-bottom: 10px;
      }
    }
  }
  .route-card-section-included-list {
    min-height: 180px;
    @media (max-width: 992px) {
      min-height: auto;
    }
  }

  .route-btn-container {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
  }
  .route-btn {
    font-size: 11px !important;
    padding: 5px 2px;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .font-check-in {
    font-style: italic;
    color: gray;
  }
  .route-point-font {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: gray;
  }
  .route-point-font-info {
    margin: 0;
    font-size: 12px;
    color: gray;
  }
  .route-voyage-line {
    position: relative;
    width: 100%;
    border-bottom: 1px dashed gray;
    margin-bottom: -6px;
  }
  .route-box-checkin-title {
    color: gray;
    font-size: 15px;
    font-weight: bold;
  }

  // ROUTE SIDE SHOW

  .sidebar-route-info {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10000;
    height: 100vh;
    width: 500px;
    max-width: 90%;
    background: white;
    transition: all 0.4s ease-in-out;
    opacity: 1;
    border-radius: 40px 0 0 40px;
    padding: 20px 0 0 0;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &.collapsed {
      transform: translateX(550px);
      opacity: 0;
    }

    iframe {
      width: 100%;
      height: 500px;
    }
  }

  .sidebar-route-info-back {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
    z-index: 1000;
  }

  .sidebar-route-info-bottom-btn {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(
      to top,
      white,
      white,
      white,
      rgba(255, 255, 255, 0) 75%
    );
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .itinerary-description-days-title {
    color: #aa182c;
  }

  .itinerary-description-days-item {
    display: flex;
    margin: 15px 0;
    cursor: pointer;
  }

  .itinerary-description-days-item-day {
    background: #aa182c;
    border-radius: 20px 0 0 20px;
    color: white;
    padding: 10px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itinerary-description-days-item-destiny {
    background: #f7e8ea;
    border-radius: 0 20px 20px 0;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 10px;
  }
  .itinerary-description-days-item-destiny-title {
  }
  .itinerary-description-days-item-destiny-description {
    font-size: 10px;
    color: gray;
    font-style: italic;
  }
}
