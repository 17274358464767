#index-admin-navbar {
  h4 {
    font-family: 'Arial Black', 'Arial';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 135%;
    letter-spacing: 0.01em;
    color: $primary-red;
  }
}

.admin-navbar-card-header {
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 10px;
  height: 80px;
  width: 100%;
  h3 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 135%;
    /* or 20px */

    letter-spacing: 0.01em;
  }
}

.btn-navbar-cards {
  background: $gray-medium-custom;
  color: $white;
  width: 0.2rem;
  border-radius: 10px;
  padding: 8px 25px;
  border: 0px;
  opacity: 1 !important;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.085em;
  border: 1px solid $white;
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-red-dark;
    border: 1px solid $white;
  }
  &:hover {
    background-color: $primary-red-dark;
    border: 1px solid $white;
  }
  &:focus {
    background-color: $primary-red-dark;
    border: 1px solid $white;
    box-shadow: none !important;
  }
}

.white-card {
  background: #ffffff;
}

#index-admin-navbar {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.eSpiEt {
  box-shadow: -8px -3px 30px rgba(0, 0, 0, 0.04),
    0px 25px 40px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px;
  border: 0px;
  margin: 0px 15px 0px 0px;
  max-width: 180px;
  min-width: 180px;
}

.jczFMF {
  background-color: $primary-red-60;
  border-radius: 0px 0px 20px 20px;
}

.bHidxu {
  background-color: $primary-red;
  border-radius: 0px 0px 20px 20px;
}

.hhJmdS {
  background: $white;
  box-shadow: -8px -3px 30px rgba(0, 0, 0, 0.04),
    0px 25px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid $white;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
  &:hover {
    border: 1px solid $primary-red;
    background: $primary-red-10;
  }
}

.gLJdbC {
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #ffffff75;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
}

.lmKFgs {
  background-color: $gray-light-custom;
  border: 0px;
  margin: 0px 15px 0px 0px;
  max-width: 180px;
  min-width: 180px;
}

.blxcdn {
  background-color: $gray-medium-custom;
  border: 0px;
  border-radius: 0px 0px 20px 20px;
}

.edit-btn {
  padding: 0px;
  border-radius: 50px;
  background-color: $white;
  border: 1px $primary-red solid;

  .d-inline-block {
    margin: 0px 5px !important;
  }

  svg {
    width: 10px !important;
    height: 10px !important;
  }
  &:hover,
  &:focus {
    background-color: $primary-red-10;
  }
  svg {
    color: $primary-red;
    width: 10px !important;
    height: 10px !important;
  }
}

.delete-btn {
  padding: 0px;
  border-radius: 50px;
  background-color: $primary-red;
  border: 1px $primary-red solid;

  .d-inline-block {
    margin: 0px 5px !important;
  }

  svg {
    width: 10px !important;
    height: 10px !important;
  }
}

.container-cards-navbar {
  max-width: 100vw;
  overflow-x: auto;
  padding: 20px 32px 32px 32px;
  @include scrollbar(rgba(179, 177, 177, 0.31), $white);
}

.subcategory-buttons {
  justify-content: flex-end;
  margin-right: 5px;
  margin-top: 3px;
}
