.title-landing {
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
  @media (max-width: 600px) {
    font-size: 2rem;
  }
}

.title-booktrip {
  text-align: left;
  text-transform: capitalize;

  @media (max-width: 991px) {
    text-align: center;
  }
}
.form-booktrip {
  @media (max-width: 767px) {
    margin-top: -30px;
    margin-bottom: -20px;
  }
  @media (max-width: 991px) {
    .hidden-sm {
      display: none;
    }
  }
}

.second-section-cargo {
  min-height: 400px;
  width: 90%;
  margin: 60px auto;
}

.second-section-tourism {
  margin-top: -1px;
}

.second-section {
  background-color: $primary-red-10;
  background-size: 'cover';
  background-position: 'center';
  background-repeat: 'no-repeat';
  overflow: hidden;
  min-height: 420px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .ship-box {
    width: 45vw;

    @media (max-width: 991px) {
      width: 90vw;
      margin-left: -5vw;
    }
  }
  .ship-img {
    width: 140%;
    margin-left: -25% !important;
    margin-top: 20px;

    @media (max-width: 991px) {
      width: 270%;
      margin-left: -75% !important;
      margin-top: 40px;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 2% !important;
    }
  }

  .departures-box {
    width: 55vw;
    padding-left: 5vw;

    .departures-box-title {
      @media (max-width: 991px) {
        margin-left: 20px;
      }
    }

    .departures-box-white-title {
      color: white;
    }

    .next-departures-box-cargo {
      @include scrollbar(rgba(179, 177, 177, 0), $gray-light-custom !important);

      .box-counts:hover {
        background-color: black !important;
      }
    }

    .next-departures-box {
      display: flex;
      align-items: center;
      overflow-x: auto;
      @include scrollbar(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));

      .box-counts {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        border-radius: 15px;
        padding: 10px;
        padding-top: 15px;
        margin-right: 20px;
        min-width: 150px;
        max-height: 145px;
        width: 150px !important;
        height: 145px !important;
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.04);
        transition: all 0.3s;
        cursor: pointer;

        .destination {
          text-align: center;
          font-size: 0.8rem;
          letter-spacing: -0.03rem;
        }

        h3,
        h6,
        p {
          color: black;
          font-weight: bold;
        }

        &:hover {
          background-color: $primary;
          color: white;

          .destination,
          h3,
          h6,
          p {
            color: white;
          }
        }

        .label-text {
          font-size: 8px;
          font-weight: 100;
          margin-bottom: -5px;
          padding-bottom: 0;
        }
      }

      .box-counts:hover {
        background-color: $primary;
      }
    }

    @media (max-width: 991px) {
      width: 100vw;
      padding: 50px 0 50px 0px;
      margin-right: -20px;
      margin-left: -10px;
    }
  }
}

.container-subheader {
  background-color: white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 25px 40px 0px rgba(146, 146, 146, 0.3);
  position: fixed;
  min-width: 100% !important;
  max-width: 100vw;
  height: 75px;
  top: 55px;
  left: 15px;
  padding-top: 5px;
  z-index: 500;
  &.subheader {
    transition: 0.2s all ease-in-out;
    opacity: 0;
    transform: translateY(-10px);
    &.show {
      opacity: 1;
      transform: translateY(0px);
    }
    .form-group {
      display: flex;
      flex-direction: column;
    }
    .box-subheader {
      align-items: flex-start !important;
      @media (max-width: 991px) {
        display: none !important;
      }
    }
  }
  @media (max-width: 991px) {
    display: none !important;
    visibility: hidden;
  }

  .btn-reservation {
    border-radius: 10px;
    background-color: $primary !important;
    font-family: Arial !important;
    font-weight: 700 !important;
    padding: 8px 15px !important;
    height: 42px !important;
    margin-top: 20px !important;

    &:disabled {
      background-color: $gray-medium-custom !important;
      border: 1px solid $gray-medium-custom !important;
      color: #fff !important;
    }
  }
  input {
    height: 28px !important;
    max-height: 28px !important;
  }
}

.card-reviewers {
  min-width: 300px;
  overflow: 'hidden';
  margin-bottom: 20px;
  padding-bottom: 30px;
  background-color: #fff !important;
  border-radius: 20px !important;
  border: 0px !important;
}

.fifth-section {
  padding: 6vh 8vw 10vh 8vw;
  background-color: white;

  .title {
    font-size: 2rem;
  }
  p {
    text-align: justify;
  }

  @media (max-width: 991px) {
    padding: 5vh 3vw;

    .col-right {
      padding: 10%;
      .btn-mobile {
        padding: 0.8rem;
        border: 1px solid white;

        &.white {
          background-color: white;
          color: black;
        }
      }
    }
  }

  .carousel-landing {
    height: 300px;

    .img-fifth-section {
      height: 300px;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.modal-booktrip {
  .btn-book-responsive {
    display: none !important;
  }
}

.cargo-card-section-container {
  margin: 20px auto;
  .negative-margin-left-text {
    margin-left: -30px !important;
  }
}

.landing-cargo-statistics-container {
  display: block;
  flex-direction: row;
}

.guides-margin-top {
  margin-top: 15vh;
}
