.model-selector-container {
  position: absolute;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #f7e8ea;
  border-radius: 20px;
  pointer-events: auto;
  z-index: 1000 !important;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
    padding: 0;
    padding: 5vw;
    width: 95%;
    position: relative;
    margin-bottom: 10px;
  }
}

.model-selector-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  background: white;
  color: #aa182c;
  font-weight: bold;
  margin: 0px 5px;
  border: 1px solid #aa182c;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px;
  }
}

.model-selector-box-active {
  background: #aa182c;
  transition: all 0.4s ease-in-out;
}
.model-selector-hover:hover {
  background: #f7e8ea;
  transition: all 0.4s ease-in-out;
}

.model-selector-box-text {
  color: #aa182c;
  font-weight: bold;
  white-space: nowrap;
  padding: 5px 15px;
  min-width: 150px;
}

.custom-selector-container {
  @media (max-width: 768px) {
    height: 70px;
  }

  p {
    font-size: 13px !important;
    margin: 5px auto 0 5px !important;
  }

  .custom-selector {
    p {
      font-size: 13px !important;
      margin: 0 auto 0 5px !important;
    }
    background: white;
    border: 1px solid lightgray;
    padding: 2px 0px;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    width: 95%;
    z-index: 100 !important;

    @media (max-width: 768px) {
      position: inherit !important;
      z-index: none !important;
      width: 100%;
    }

    .main-content {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        letter-spacing: -0.7px;
        color: gray;
      }
    }

    .content-list-open {
      background: white;
      padding-top: -10px;
      transition: all 0.4s ease-in-out;
      z-index: 10000 !important;
      max-height: 200px;
      overflow-y: scroll;
      @media (max-width: 768px) {
        position: relative !important;
        z-index: 1000 !important;
        width: 100%;
      }
    }
    .content-list-close {
      height: 0;
      display: none;
      transition: all 0.4s ease-in-out;
    }
    .content-list-item {
      padding: 5px 10px;
      margin: 2px 5px;
      border-radius: 10px;
      transition: all 0.4s ease-in-out;
      font-size: 12px;
      cursor: pointer;
    }
    .content-list-item:hover {
      background: #f7e8ea;
      transition: all 0.2s ease-in-out;
    }
  }
}

// PassengerSelector dentro de CustomSelector.js
.passenger-selector {
  p {
    font-size: 12px !important;
    margin: 0 !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  .passenger-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .passenger-type {
      font-weight: bold;
    }

    .passenger-description {
      font-size: 11px;
      color: gray;
    }
  }

  .passenger-controls {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .passenger-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #aa182c;
      fill: white;
      padding: 6px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .passenger-icon:hover {
      background: red;
      padding: 5px;
      transition: all 0.2s ease-in-out;
    }
  }
}
