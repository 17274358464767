@keyframes card-opacity-animation {
  0% {
    opacity: 0;
  }
  50% {
    visibility: visible;
  }
  90% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes card-reverse-opacity-animation {
  0% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.animation-in {
  animation: card-opacity-animation 1s ease-in-out forwards;
  &.card-body {
    width: 100% !important;
    .card-body-container {
      display: flex !important;
      animation: card-opacity-animation 1s ease-in-out forwards;
      visibility: visible !important;
    }
  }
}

.animation-out {
  animation: card-reverse-opacity-animation 1s ease-in-out forwards;
  &.card-body {
    .card-body-container {
      animation: card-reverse-opacity-animation 1s ease-in-out forwards;
    }
  }
}

.cargo-landing-service {
  .cargo-landing-card-container {
    @media (min-width: 1200px) {
      padding-left: 5%;
      padding-right: 5%;
    }
    @media (max-width: 991px) {
      background-color: black;
      padding: 2rem;
    }
  }

  .cargo-landing-service-card {
    max-width: 265px;
    border-radius: 20px;
    margin-bottom: 2rem;
    background-color: $white !important;
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.05) !important;

    .card-image-container {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-width: 100%;
      max-height: 225px;
      object-fit: cover;
    }
    .card-title-text {
      font-size: 13px;
      font-weight: 700;
    }

    .card-body {
      opacity: 0;
      visibility: hidden;

      height: auto !important;
      min-height: 50px !important;
      max-height: 250px !important;

      position: absolute;
      bottom: 0px;
      z-index: 10;
      background-color: $white;
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      transition: all 1s ease-in;

      @media (max-width: 991px) {
        height: auto !important;
        min-height: 50px !important;
        max-height: 300px !important;
        opacity: 1;
        position: relative;
        top: 0;
        visibility: visible;
      }

      .card-body-container {
        opacity: 0;
        display: flex;
        visibility: hidden;
        @media (max-width: 991px) {
          display: flex;
          opacity: 1;
          visibility: visible;
        }

        .card-body-category-image {
          max-width: 52px;
          max-height: 32px;
        }
        .card-body-category-text {
          font-size: 13px;
          font-weight: 400;
          height: 25px;
        }
      }
    }
  }

  .cargo-landing-service-title {
    font-size: 32px;
    font-weight: 900;
  }

  .cargo-landing-service-description {
    font-size: 13px;
    font-weight: 400;
  }

  .cargo-landing-service-background {
    background-color: black;
    min-height: 100px;
    padding-bottom: 20px;
    @media (max-width: 991px) {
      padding-bottom: 40px;
    }
  }

  .middle-background-stripe {
    background-color: black;
    width: 100%;
    height: 175px;
    position: relative;
    top: -125px;
    z-index: -1;
    margin-bottom: -175px;
    @media (max-width: 1199px) {
      height: 525px;
      top: -475px;
      margin-bottom: -525px;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.landing-cargo {
  overflow-x: hidden;
}

.landing-cargo-transport-title-service {
  text-align: center;

  @media (max-width: 635px) {
    padding: 2rem;
    font-size: 25px !important;
  }
}
